<template>
  <div class="electric">
    <div class="facilityBox" v-loading="loading">
      <div>
        <div id="container" style="width:auto;height:84vh;"></div>
      </div>
    </div>
    <el-dialog
      :title="dialog.title"
      :visible.sync="dialog.visible"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="520px"
    >
      <el-form
        :model="fenceForm"
        ref="fenceForm"
        label-width="120px"
        :rules="rules"
      >
        <el-form-item label="围栏名称" prop="FenceName">
          <el-input v-model="fenceForm.FenceName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="围栏中心位置" prop="FenceCenterLocation" required>
          <el-input
            v-model="fenceForm.FenceCenterLocation"
            autocomplete="off"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="围栏中心经度" prop="Longitude" required>
          <el-input
            v-model="fenceForm.Longitude"
            autocomplete="off"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="围栏中心纬度" prop="Latitude" required>
          <el-input
            v-model="fenceForm.Latitude"
            autocomplete="off"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="围栏半径(米)" prop="Radius">
          <el-input v-model="fenceForm.Radius" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelFence">取 消</el-button>
        <el-button type="primary" @click="submitFence('fenceForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-collapse v-model="activeNames" class="collapse_box">
      <el-collapse-item title="围栏列表" name="1">
        <div class="item_box">
          <el-input
            placeholder="请输入围栏名称"
            style="width: 200px"
            v-model="pagination.FenceName"
            class="input-with-select"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="searchName"
            ></el-button>
          </el-input>
          <el-button @click="addCircle" type="primary" class="head_btn"
            >新增</el-button
          >
          <el-button @click="showFence" type="primary" class="head_btn"
            >显示所有</el-button
          >
          <ul
            class="electric_box"
            v-infinite-scroll="load"
            infinite-scroll-disabled="disabled"
            style="overflow:auto"
          >
            <li
              v-for="(item, index) in tableData"
              :class="isActive == index ? 'active' : ''"
              class="infinite-list-item"
              @click="searchitem(item, index)"
              :key="index"
            >
              <span
                ><span style="font-weight: 600">围栏名称: </span
                >{{ item.FenceName }}</span
              >
              <div class="">
                <el-button type="text" @click.stop="editCircle(item)"
                  >修改</el-button
                >
                <el-button type="text" @click.stop="removeCircle(item)"
                  >删除</el-button
                >
              </div>
            </li>
            <p class="load_item" v-if="loadingScroll">加载中...</p>
            <p class="load_item" v-if="noMore">没有更多了</p>
          </ul>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
import {
  GetFenceList,
  AddFence,
  DelFence,
  EditFence,
  GetFenceDeatil,
} from "@/api/platform/electric/index";
import { reverseCoord } from "@/api/common/common";
export default {
  data() {
    return {
      loading: false,
      map: null, // 地图
      marker: null, // 标注点
      center: new TMap.LatLng(28.606169, 115.934794),
      editor: null,
      activeNames: ["1"],
      pagination: {
        //分页控件相关参数
        page: 1, //当前所处的页码
        total: 10, //总条数
        FenceName: "",
      },
      tableData: [],
      noMore: false,
      loadingScroll: false,
      isActive: null,
      circles: null,
      dialog: {},
      fenceForm: {
        FenceName: "",
        FenceCenterLocation: "",
        Longitude: "",
        Latitude: "",
        Radius: "",
      },
      rules: {
        FenceName: [
          { required: true, message: "请输入围栏名称", trigger: "blur" },
        ],
        Radius: [
          { required: true, message: "请输入围栏半径", trigger: "blur" },
        ],
      },
      points: [],
    };
  },
  computed: {
    disabled() {
      return this.loadingScroll || this.noMore;
    },
  },
  methods: {
    initMap() {
      // 地图初始化
      this.map = new TMap.Map("container", {
        center: this.center,
      });
      // 围栏数据
      this.circles = new TMap.MultiCircle({
        map: this.map,
        styles: {
          circle: new TMap.CircleStyle({
            color: "rgba(41,91,255,0.16)",
            showBorder: true,
            borderColor: "rgba(41,91,255,1)",
            borderWidth: 2,
          }),
        },
        geometries: [],
      });
    },
    load() {
      // 围栏数据初始化
      this.loadingScroll = true;
      let params = {
        pageIndex: this.pagination.page++,
        pageSize: 10,
        FenceName: this.pagination.FenceName,
      };
      this.handleGetData(params);
    },
    searchName() {
      // 围栏名称搜索
      this.pagination.page = 1;
      this.tableData = [];
      this.load();
    },
    searchitem(row, index) {
      // 围栏名称点击
      this.map.setCenter(new TMap.LatLng(row.Latitude, row.Longitude));
      // this.isActive = index;
      // this.map.destroy()
      // this.center = new TMap.LatLng(row.Latitude, row.Longitude)
      // this.initMap()
      // this.circles.add({
      //   styleId: 'circle',
      //   center: new TMap.LatLng(row.Latitude, row.Longitude),
      //   radius: row.Radius,
      //   id: 'circle' + row.Latitude + row.Longitude
      // })
      // this.points = [{
      //   id: 'circle' + row.Latitude + row.Longitude,
      //   styleId: 'marker',
      //   position: new TMap.LatLng(row.Latitude, row.Longitude),
      //   properties: {
      //     radius: row.Radius,
      //     FenceCenterLocation: row.FenceCenterLocation,
      //     FenceName: row.FenceName,
      //     FenceID: row.FenceID,
      //   }
      // }]
      // this.setMarker()
    },
    showFence() {
      this.points = [];
      this.map.destroy();
      this.initMap();
      this.tableData.forEach((item, index) => {
        this.circles.add({
          styleId: "circle",
          center: new TMap.LatLng(item.Latitude, item.Longitude),
          radius: item.Radius,
          id: "circle" + item.Latitude + item.Longitude + index,
        });
        this.points.push({
          id: "circle" + item.Latitude + item.Longitude + index,
          styleId: "marker",
          position: new TMap.LatLng(item.Latitude, item.Longitude),
          properties: {
            radius: item.Radius,
            FenceCenterLocation: item.FenceCenterLocation,
            FenceName: item.FenceName,
            FenceID: item.FenceID,
          },
        });
      });
      this.setMarker();
    },
    handleGetData(params) {
      // 获取围栏数据
      GetFenceList(params)
        .then((res) => {
          // this.tableData = this.tableData.concat(res.data.DataList)
          this.pagination.total = Number(res.data.TotalCount);
          if (
            Math.ceil(Number(res.data.TotalCount) / 10) < this.pagination.page
          ) {
            this.noMore = true;
          }
          this.loadingScroll = false;
          res.data.DataList.forEach((item, index) => {
            this.circles.add({
              styleId: "circle",
              center: new TMap.LatLng(item.Latitude, item.Longitude),
              radius: item.Radius,
              id: "circle" + item.Latitude + item.Longitude + index,
            });
            this.points.push({
              id: "circle" + item.Latitude + item.Longitude + index,
              styleId: "marker",
              position: new TMap.LatLng(item.Latitude, item.Longitude),
              properties: {
                radius: item.Radius,
                FenceCenterLocation: item.FenceCenterLocation,
                FenceName: item.FenceName,
                FenceID: item.FenceID,
              },
            });
            this.tableData.push(item);
          });
          this.setMarker();
        })
        .catch((err) => {
          if (err.code === 403) {
            this.$router.push("/power/nopermission");
          }
        });
    },
    setMarker() {
      this.marker = new TMap.MultiMarker({
        map: this.map,
        styles: {
          //点标注的相关样式
          marker: new TMap.MarkerStyle({
            width: 25,
            height: 35,
            anchor: { x: 13, y: 32 },
          }),
        },
        geometries: [...this.points],
      });
      //初始化mark标记点点击事件
      var infoWindow = new TMap.InfoWindow({
        map: this.map,
        position: new TMap.LatLng(0, 0),
        offset: { x: 0, y: -32 }, //设置信息窗相对position偏移像素，为了使其显示在Marker的上方
      });
      infoWindow.close(); //初始关闭信息窗关闭
      this.marker.on("click", function(evt) {
        infoWindow.open(); //打开信息窗
        infoWindow.setPosition(evt.geometry.position); //设置信息窗位置
        //设置信息窗内容
        infoWindow.setContent(`<div>
          <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">地址：</span>${evt.geometry.properties.FenceCenterLocation}</p>
          <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">经纬度：</span>${evt.geometry.position.lng}-${evt.geometry.position.lat}</p>
          <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">围栏名称：</span>${evt.geometry.properties.FenceName}</p>
          <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">围栏半径：</span>${evt.geometry.properties.radius}</p>
          <p>
            <button class="address_btn"
              onclick="handleEditFence('${evt.geometry.properties.FenceID}',
              '${evt.geometry.properties.FenceName}','${evt.geometry.properties.radius}',
              '${evt.geometry.position.lng}','${evt.geometry.position.lat}','${evt.geometry.properties.FenceCenterLocation}')">修改</button>
            <button class="address_btn" onclick="removeFence('${evt.geometry.properties.FenceID}')">删除</button>
          </p>
          </div>`);
      });
    },
    addCircle() {
      // 新增围栏
      this.editor = new TMap.tools.GeometryEditor({
        map: this.map, // 编辑器绑定的地图对象
        overlayList: [
          {
            overlay: new TMap.MultiCircle({
              map: this.map,
            }),
            id: "circle",
          },
        ],
        actionMode: TMap.tools.constants.EDITOR_ACTION.DRAW, // 编辑器的工作模式
        activeOverlayId: "marker", // 激活图层
        selectable: true, // 开启点选功能
        snappable: true, // 开启吸附
      });
      this.editor.setActionMode(TMap.tools.constants.EDITOR_ACTION.DRAW);
      this.editor.setActiveOverlay("circle");
      let _self = this;
      // 新增围栏绘制成功
      this.editor.on("draw_complete", (geometry) => {
        let params = {
          lat: geometry.center.lat,
          lng: geometry.center.lng,
        };
        this.loading = true;
        reverseCoord(params).then((res) => {
          this.loading = false;
          this.dialog = {
            visible: true,
            type: "add",
            title: "新增围栏",
          };
          this.fenceForm = {
            FenceCenterLocation: res.data.address,
            Longitude: geometry.center.lng,
            Latitude: geometry.center.lat,
            Radius: geometry.radius,
          };
        });
      });
    },
    editCircle(row) {
      // 编辑围栏
      this.dialog = {
        visible: true,
        type: "edit",
        title: "修改围栏",
      };
      this.fenceForm = row;
    },
    removeCircle(row) {
      // 删除
      this.$confirm("是否确认删除当前围栏", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          DelFence(row.FenceID).then((res) => {
            this.points = [];
            this.tableData = [];
            this.$nextTick(() => {
              this.pagination.page = 1;
              this.load();
            });
            this.map.destroy();
            this.initMap();
          });
        })
        .catch(() => {});
    },
    removeFence(FenceID) {
      let data = {
        FenceID,
      };
      this.removeCircle(data);
    },
    // 取消
    cancelFence() {
      if (this.dialog.type == "add") {
        this.showFence();
      }
      this.dialog.visible = false;
    },
    submitFence(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dialog.type === "add") {
            AddFence(this.fenceForm).then((res) => {
              this.center = new TMap.LatLng(
                this.fenceForm.Latitude,
                this.fenceForm.Longitude
              );
              this.map.destroy();
              this.initMap();
              this.tableData = [];
              this.points = [];
              this.dialog.visible = false;
              this.pagination.page = 1;
              this.load();
            });
          } else {
            EditFence(this.fenceForm).then((res) => {
              this.center = new TMap.LatLng(
                this.fenceForm.Latitude,
                this.fenceForm.Longitude
              );
              this.map.destroy();
              this.initMap();
              this.tableData = [];
              this.points = [];
              this.dialog.visible = false;
              this.pagination.page = 1;
              this.load();
            });
          }
        } else {
          return false;
        }
      });
    },
    handleEditFence(...data) {
      const [
        FenceID,
        FenceName,
        Radius,
        Longitude,
        Latitude,
        FenceCenterLocation,
      ] = data;
      this.dialog = {
        visible: true,
        type: "edit",
        title: "修改围栏",
      };
      this.fenceForm = {
        FenceID,
        FenceName,
        Radius,
        Longitude,
        Latitude,
        FenceCenterLocation,
      };
    },
  },
  mounted() {
    this.initMap();
    window.removeFence = this.removeFence;
    window.handleEditFence = this.handleEditFence;
  },
};
</script>

<style scoped lang="scss">
.electric {
  min-width: 1000px;
  position: relative;
  width: 100%;
}
.collapse_box {
  position: absolute;
  top: 60px;
  left: 60px;
  z-index: 1001;
  border-radius: 6px;
  width: 400px;
  background-color: #fff;
  border: none;
  .item_box {
    padding: 6px 10px;
  }
  .electric_box {
    height: 300px;
    overflow-y: auto;
    margin-top: 6px;
    .infinite-list-item {
      padding: 0px 6px;
      height: 36px;
      border-bottom: 1px solid #eee;
      line-height: 36px;
      display: flex;
      flex: 1;
      justify-content: space-between;
      cursor: pointer;
    }
    .infinite-list-item:hover {
      background-color: #ddd;
    }
    .active {
      background-color: #ddd;
    }
    .load_item {
      text-align: center;
      margin-top: 6px;
      font-size: 12px;
    }
  }
  .electric_box::-webkit-scrollbar {
    width: 4px;
  }
  .electric_box::-webkit-scrollbar-track-piece {
    background-color: #fff;
  }
  .electric_box::-webkit-scrollbar-thumb {
    background-color: #eee;
  }

  .el-collapse-item {
    box-sizing: border-box;
  }
  ::v-deep .el-collapse-item__header {
    text-align: center;
    display: block;
    background-color: #409eff;
    color: #fff;
    font-size: 14px;
    border-radius: 4px;
    .el-collapse-item__arrow {
      float: right;
      margin-top: 16px;
      margin-right: 16px;
    }
  }
}
.head_btn {
  margin-left: 8px;
}
</style>
<style lang="scss">
.address_btn {
  background-color: #409eff;
  border-color: #409eff;
  padding: 7px 14px;
  color: #fff;
  border: none;
  border-radius: 4px;
}
</style>
